import { z } from 'zod';
import { dateToUTC } from 'shared/src/zod-utils';
import { AgDateFilter, AgNumberFilter, AgSetFilter } from './ag-grid-filter-types';

export const MediaPlatformEntity = z.object({
  id: z.string(),
  media_platform_id: z.number(),
  entity_id: z.string(),
  raw_entity: z.object({}).catchall(z.any()).optional(),
  entity_type: z.string(),
  entity_name: z.string()
});

export type MediaPlatformEntity = z.infer<typeof MediaPlatformEntity>;

export const MediaBuy = z.object({
  id: z.string(),
  name: z.string(),
  media_platform_id: z.number(),
  media_platform_entity: MediaPlatformEntity,
  start_date: dateToUTC,
  end_date: dateToUTC,
  budget: z.coerce.number(),
  campaign_numbers: z.string().array().optional()
});

export type MediaBuy = z.infer<typeof MediaBuy>;

export const MediaBuyLink = z.object({
  media_buy: MediaBuy,
  link_budget: z.coerce.number(),
  link_name: z.string().nullable(),
  target_unit_cost: z.coerce.number()
});
export type MediaBuyLink = z.infer<typeof MediaBuyLink>;

export const SyncedPlatformEntity = z.object({
  media_platform_id: z.number(),
  raw_entity: z.object({}).catchall(z.any()).optional(),
  updated_at: dateToUTC.optional(),
  entity_id: z.string(),
  entity_type: z.string(),
  entity_name: z.string(),
  advertiser_id: z.string().optional(),
  fetched_at: dateToUTC.optional()
});

export type SyncedPlatformEntity = z.infer<typeof SyncedPlatformEntity>;

export const SyncedMediaBuy = MediaBuy.omit({
  media_platform_entity: true
}).extend({
  updated_at: dateToUTC.optional(),
  entity_id: z.string(),
  entity_type: z.string(),
  advertiser_id: z.string().nullable(),
  advertiser_type: z.string(),
  campaign_numbers: z.string().array()
});

export type SyncedMediaBuy = z.infer<typeof SyncedMediaBuy>;

export const MediaBuyTableRow = MediaBuy.omit({ media_platform_entity: true });
export type MediaBuyTableRow = z.infer<typeof MediaBuyTableRow>;

export const MediaBuySearchRow = MediaBuy.omit({
  media_platform_entity: true,
  budget: true,
  campaign_numbers: true
}).extend({
  line_items: z.array(
    z.object({
      start_date: dateToUTC,
      end_date: dateToUTC
    })
  )
});
export type MediaBuySearchRow = z.infer<typeof MediaBuySearchRow>;

export const MediaBuySearchRecord = MediaBuy.omit({
  media_platform_entity: true,
  budget: true,
  campaign_numbers: true
}).extend({
  availability: z.union([
    z.literal('available'),
    z.literal('unavailable'),
    z.literal('invalid_dates'),
    z.literal('incompatible_platform'),
    z.literal('unavailable_platform')
  ])
});

export type MediaBuyAvailability =
  | 'available'
  | 'unavailable_platform'
  | 'unavailable'
  | 'incompatible_platform'
  | 'invalid_dates';

export const LineItemMediaBuy = z.object({
  id: z.string(),
  line_item_id: z.string(),
  media_buy_id: z.string()
});
export type LineItemMediaBuy = z.infer<typeof LineItemMediaBuy>;

export const NewMediaBuy = MediaBuy.omit({
  media_platform_entity: true
}).extend({
  line_item_id: z.string()
});
export type NewMediaBuy = z.infer<typeof NewMediaBuy>;

export const MediaBuyUpdate = MediaBuyLink.partial()
  .omit({ media_buy: true })
  .merge(
    MediaBuy.partial().omit({ id: true, media_platform_entity: true }).extend({ id: z.string() })
  );

export type MediaBuyUpdate = z.infer<typeof MediaBuyUpdate>;

export const MediaBuyChanges = z.union([
  z.object({
    type: z.literal('new'),
    data: NewMediaBuy
  }),
  z.object({
    type: z.literal('update'),
    data: MediaBuyUpdate
  })
]);
export type MediaBuyChanges = z.infer<typeof MediaBuyChanges>;

export const MediaBuySortColumn = z.union([
  z.literal('name'),
  z.literal('media_platform_id'),
  z.literal('start_date'),
  z.literal('end_date'),
  z.literal('budget')
]);
export type MediaBuySortColumn = z.infer<typeof MediaBuySortColumn>;
export const mediaBuySortColumns = MediaBuySortColumn.options.map(option => option.value);

export const SortDirection = z.union([z.literal('asc'), z.literal('desc')]);

export const MediaBuySort = z.object({
  column: MediaBuySortColumn,
  direction: SortDirection
});
export type MediaBuySort = z.infer<typeof MediaBuySort>;

export const MediaBuysFilterTypes = z.object({
  media_platform_id: AgSetFilter.optional(),
  budget: AgNumberFilter.optional(),
  start_date: AgDateFilter.optional(),
  end_date: AgDateFilter.optional(),
  campaign_id: AgSetFilter.optional(),
  advertiser_id: AgSetFilter.optional(),
  advertiser_name: AgSetFilter.optional(),
  campaign_name: AgSetFilter.optional()
});
export type MediaBuysFilterTypes = z.infer<typeof MediaBuysFilterTypes>;

export const MediaBuyListRequest = z.object({
  page: z.number(),
  search: z.string().optional(),
  sort: MediaBuySort.optional(),
  filters: MediaBuysFilterTypes
});

export type MediaBuyListRequest = z.infer<typeof MediaBuyListRequest>;

export const MediaBuySearchRequest = z.object({
  page: z.number(),
  search: z.string(),
  line_item_id: z.string()
});

export type MediaBuySearchRequest = z.infer<typeof MediaBuySearchRequest>;

export const MediaBuyListResult = z.object({
  media_buys: z.array(MediaBuyTableRow),
  total: z.number()
});

export type MediaBuyListResult = z.infer<typeof MediaBuyListResult>;

export const MediaBuySearchResult = z.object({
  media_buys: z.array(MediaBuySearchRecord),
  total: z.number(),
  platform_count: z.number()
});

export type MediaBuySearchResult = z.infer<typeof MediaBuySearchResult>;
